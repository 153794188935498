import { Box, Typography } from "@mui/material";
import React from "react";
import * as WarningMsg from "./WarningMsg.json";
import WarningTriangle from "./images/WarningTriangle";

 const WarningBox: React.FC<{}> = () => {
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            gap:"8px",
            boxSizing:"border-box",
            backgroundColor:"#EDECF0",
            width:"552px",
            padding:"16px",
            position:"relative",
            left:"50%",
            mt:"1rem",
            transform:"translateX(-50%)",
            minHeight:"144px",
            "@media (max-width: 625px)":{
                width:"342px",
                minHeight:"160px",
            }
            }}>
            <Box sx={{display:"flex", gap:"8px"}}>
                <WarningTriangle sx={{color:"#0D3050", alignSelf:"start"}}/>
                <Typography sx={{fontWeight:600, fontSize:"16px", lineHeight:"24px"}}>Svårt att logga in?</Typography>
            </Box>
            <Box sx={{
                display:"flex", 
                flexDirection:"column",
                width:"520px",
                lineHeight:"24px",
                "@media (max-width: 625px)":{
                    width:"310px", 
                    fontSize:"14px"
                }
                }}>
                    <Typography sx={{fontSize:"16px", "@media (max-width: 625px)":{fontSize:"14px"}}}>
                      {WarningMsg.msg}
                    </Typography>
                    <Typography sx={{fontSize:"16px", "@media (max-width: 625px)":{fontSize:"14px"}}}>
                        {WarningMsg.phone}
                    </Typography>
            </Box>
        </Box>
    )
};
export default WarningBox;
