import React, { MutableRefObject, useEffect } from "react";

import { Box, Button, Typography } from "@mui/material";


interface BankIdThisDeviceLoginProps {
  bankIdOpened: boolean;
  loginFailed: boolean;
  setBankIdOpened: (bankIdOpened: boolean) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  startBankIdLogin: () => Promise<void>;
  bankIdUrl: MutableRefObject<string>;
  userMessage: string;
  startImmediately: boolean;
}

const BankIdThisDeviceLogin: React.FC<BankIdThisDeviceLoginProps> = ({
  bankIdOpened,
  loginFailed,
  onBackClick,
  setBankIdOpened,
  startBankIdLogin,
  bankIdUrl,
  userMessage,
  startImmediately,
}: BankIdThisDeviceLoginProps) => {
  const onOpenBankIdClick = async (
    _event?: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    await startBankIdLogin();
    setBankIdOpened(true);
    window.location.assign(bankIdUrl.current);
  };
  useEffect(() => {
    if (startImmediately) {
      onOpenBankIdClick();
    }
  },[startImmediately])
  return (
    <>
      <Box
        component="img"
        src="/images/BankID_logo.svg"
        alt="BankID Logo"
        sx={{
          width: 140,
          height: 140,
          ml: "auto !important", // for some reason, Stack's margin will overwrite these
          mr: "auto !important",
          mt:"0 !important",
          mb:"0 !important",
          display: "block",  
        }}
      />
      {!loginFailed &&
        (bankIdOpened ? (
          <Button
            variant="contained"
            fullWidth
            disabled
            disableRipple
            sx={{
              width:{
                md:"256px"
              },
              margin: "16px auto 0 auto !important",
              mt: 0.5,
              borderRadius: 6,
              height: 48,
              fontWeight: 700,
              fontSize: 14,
              lineHeight: "16px",
              letterSpacing: 1,
              textTransform: "none",
              "&:disabled": {
                backgroundColor: "rgba(13, 48, 80, 0.5)",
                color: "#FFFFFF",
                boxShadow: "unset",
              },
            }}
          >
            VÄNTAR PÅ APPLIKATION
          </Button>
        ) : (    
          <>
          {/* identify btn for desktop */}
           <Button
              variant="contained"
              fullWidth
              onClick={onOpenBankIdClick}
              disableRipple
              sx={{
                width:{
                  md:"328px"
                },
                display:{
                  xs:"none",
                  md:"inline-flex"
                },
                margin: "16px auto 0 auto !important",
                mt: 0.5,
                borderRadius: 6,
                height: 48,
                fontWeight: 700,
                fontSize: 14,
                lineHeight: "16px",
                letterSpacing: 1,
                textTransform: "uppercase",
                boxShadow: "unset",
                "&:hover": {
                  color: "#FFFFFF",
                  backgroundColor: "secondary.main",
                  boxShadow: "unset",
                },
                "&:active": {
                  backgroundColor:"#275284",
                  boxShadow: "unset",
                },
              }}
            >
              identifiera dig
            </Button>
              {/* identify btn for mobile */}
            <Button
              variant="contained"
              fullWidth
              onClick={onOpenBankIdClick}
              disableRipple
              sx={{
                width:{
                  md:"328px"
                },
                display:{
                  xs:"inline-flex",
                  md:"none"
                },
                margin: "16px auto 0 auto !important",
                mt: 0.5,
                borderRadius: 6,
                height: 48,
                fontWeight: 700,
                fontSize: 14,
                lineHeight: "16px",
                letterSpacing: 1,
                textTransform: "uppercase",
                boxShadow: "unset",
                "&:active": {
                  backgroundColor:"#275284",
                  boxShadow: "unset",
                },
              }}
            >
              identifiera dig
            </Button>
          </>     
        ))}
      {loginFailed && (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {userMessage}
        </Typography>
      )}
      {/* cancel btn for mobile */}
        <Button
          fullWidth
          disableRipple
          variant="text"
          color="secondary"
          sx={{
            display: {
              xs: "inline-flex",
              md: "none",
            },
            margin: "16px auto 0 auto !important",
            mt: 0.5,
            borderColor: "#D1DFF1",
            borderRadius: 6,
            textDecoration: "underline",
            height: 48,
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "16px",
            letterSpacing: 1,
            textUnderlineOffset: 4,
            color: "primary.main",
            "&:hover": {
              textDecoration: "underline",
            },
            "&:active": {
              backgroundColor:"#275284",
              textDecoration: "underline",
              boxShadow: "unset",
            },
          }}
          onClick={onBackClick}
        >
          Avbryt
        </Button>
        {/* cancel btn for desktop */}
        <Button
          fullWidth
          disableRipple
          variant="text"
          color="primary"
          sx={{
            display: {
              xs: "none",
              md: "inline-flex",
            },
            margin: "16px auto 0 auto !important",
            width:"328px",
            mt: 0.5,
            borderRadius: 6,
            textDecoration: "underline",
            height: 48,
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "16px",
            letterSpacing: 1,
            textUnderlineOffset: 4,
            color: "provider.main",
            "&:hover": {
              textDecoration: "underline",
              color: "#FFFFFF",
              backgroundColor: "secondary.main",
            },
            "&:active": {
              backgroundColor:"#275284",
            },
          }}
          onClick={onBackClick}
        >
          Avbryt
        </Button>
    </>
  );
};

export default BankIdThisDeviceLogin;
